import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { CommonService } from '@services/common.service';
import { WalletStatusService } from '@services/wallet-status.service';
import { WalletEnum } from '@shared/types/wallet-chain.type';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { Router } from '@angular/router';
import { UseAppKitAccountReturn } from '@reown/appkit';
import { TelegramService } from '@src/app/services/telegram.service';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription } from 'rxjs';

declare global {
    interface Window {
        signWithDevKey?: any;
    }
}

@Component({
    selector: 'app-connect-wallet-modal',
    templateUrl: './connect-wallet-modal.component.html',
    styleUrls: ['./connect-wallet-modal.component.less'],
})
export class ConnectWalletModalComponent implements OnInit, OnDestroy {
    connectingWallet: WalletEnum;

    WalletEnum = WalletEnum;

    sdnClient: any;
    sdnMsgParams: { did: string; message: string; random_server: any; updated: any };

    wallets = this.walletStatusService.detectWalletsBasedOnDevice();

    wcAccountChangeSub: Subscription;

    loadingRefs: NzMessageRef[] = [];

    get prefix() {
        return this.walletStatusService.prefix;
    }

    constructor(
        @Optional() public modalRef: NzModalRef,
        @Optional() public drawerRef: NzDrawerRef,
        private commonService: CommonService,
        private walletStatusService: WalletStatusService,
        private router: Router,
        public tgService: TelegramService,
        private messageService: NzMessageService
    ) {}

    ngOnInit() {
        if (this.walletStatusService.isWalletConnected && this.walletStatusService.wallet === WalletEnum.WALLET_CONNECT) {
            this.walletStatusService.web3Modal.disconnect();
        }
    }

    ngOnDestroy() {
        this.wcAccountChangeSub?.unsubscribe();
        this.removeMessage();
    }

    removeMessage() {
        this.loadingRefs.forEach(ref => {
            if (ref) {
                this.messageService.remove(ref.messageId);
            }
        });
    }

    async connect(type: WalletEnum) {
        try {
            switch (type) {
                case WalletEnum.WALLET_CONNECT:
                    await this.connectWalletConnect();
                    break;

                case WalletEnum.COINBASE:
                    await this.connectCoinBase();
                    break;

                case WalletEnum.META_MASK:
                    await this.connectMetaMask();
                    break;

                case WalletEnum.TRUST_WALLET:
                    await this.connectTrustWallet();
                    break;
            }
        } catch (err: any) {
            if (err.code === -30002) {
                this.commonService.error(err.message || null);
            }
        }
    }

    async connectWalletConnect() {
        this.connectingWallet = WalletEnum.WALLET_CONNECT;
        this.removeMessage();
        this.wcAccountChangeSub?.unsubscribe();

        try {
            this.walletStatusService.web3Modal.open();

            const accountChanges$ = new Observable<UseAppKitAccountReturn>(subscriber =>
                this.walletStatusService.web3Modal.subscribeAccount(account => subscriber.next(account))
            );

            this.wcAccountChangeSub = accountChanges$
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged((prev, curr) => prev?.isConnected === curr?.isConnected),
                    filter(data => data?.isConnected)
                )
                .subscribe(async ({ address }) => {
                    this.removeMessage();
                    this.loadingRefs.push(this.commonService.loading('Please check your wallet to sign the message...', { nzDuration: 0 }));
                    const { message } = await this.getSdnSignMsg();
                    const sign: string = await (this.walletStatusService.web3Modal.getWalletProvider() as any).request({
                        method: 'personal_sign',
                        params: [message, address],
                    });
                    this.removeMessage();

                    if (this.tgService.isInTelegramWebApp) {
                        this.tgService
                            .connectWalletWithTG({
                                wallet_address: address,
                                message: message,
                                signature: sign,
                            })
                            .then(() => {
                                this.walletStatusService.setSessionData({
                                    wallet: WalletEnum.WALLET_CONNECT,
                                    walletAddress: address,
                                    signature: sign,
                                });
                                this.commonService.success('Connection successful!');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 500);
                            })
                            .catch(err => {
                                if (err.status === 400) {
                                    this.commonService.error('Connection failed. Please select a new Wallet that has never been connected to SocialScan.');
                                } else {
                                    this.commonService.errorMessageByResponse(err);
                                }
                            })
                            .finally(() => this.close());
                    } else {
                        this.walletStatusService.setSessionData({
                            wallet: WalletEnum.WALLET_CONNECT,
                            walletAddress: address,
                            signature: sign,
                        });
                        this.goInvitation();
                        this.close();
                    }
                });
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
            console.log(err);
        }
    }

    async connectCoinBase() {
        // Initialize Coinbase Wallet SDK
        // this.connectingWallet = WalletEnum.COINBASE;
        // const coinbaseWallet = new CoinbaseWalletSDK({
        //     appName: 'SocialScan',
        //     darkMode: false,
        // });
        // const ethereum = coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', 1);
        // try {
        //     const accounts = await ethereum.send('eth_requestAccounts', []);
        //     const sdnData = await this.getSdnSignMsg(accounts[0]);
        //     if (!sdnData) {
        //         this.connectingWallet = null;
        //         // TODO: Error Message
        //         return;
        //     }
        //     const sign = await ethereum.send('personal_sign', [sdnData.message, accounts[0]]);
        //     this.walletStatusService.setSessionData(WalletEnum.COINBASE, accounts[0], sign);
        //     this.connectingWallet = null;
        //     this.close();
        //     this.goInvitation();
        // } catch (err: any) {
        //     this.commonService.error(err.message);
        //     this.connectingWallet = null;
        // }
    }

    async connectMetaMask() {
        this.connectingWallet = WalletEnum.META_MASK;
        let mmProvider = window.ethereum as any;

        if (mmProvider?.providers?.length) {
            mmProvider = mmProvider.providers?.find((item: any) => item.isMetaMask);
        }

        if (!mmProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://metamask.app.link/dapp/${window.location.protocol}//${window.location.host}/${window.location.pathname}`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await mmProvider.request({ method: 'eth_requestAccounts', params: [] });
            const { message } = await this.getSdnSignMsg();

            const sign = await mmProvider.request({ method: 'personal_sign', params: [message, accounts[0]] });
            this.walletStatusService.setSessionData({
                wallet: WalletEnum.META_MASK,
                walletAddress: accounts[0],
                signature: sign,
            });
            this.close();
            await this.goInvitation();
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err?.message);
            this.connectingWallet = null;
        }
    }

    async connectTrustWallet() {
        const trustWalletProvider = this.walletStatusService.getTrustWalletFromWindow();
        this.connectingWallet = WalletEnum.TRUST_WALLET;

        if (!trustWalletProvider) {
            if (this.commonService.isMobile) {
                // eslint-disable-next-line max-len
                window.location.href = `https://link.trustwallet.com/open_url?url=${window.location.protocol}//${window.location.host}/${window.location.pathname}&coin_id=60`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph', '_blank');
            }
            this.connectingWallet = null;
            return;
        }

        try {
            const accounts = await trustWalletProvider.request({ method: 'eth_requestAccounts' });
            const { message } = await this.getSdnSignMsg();
            const sign = await trustWalletProvider.request({
                method: 'personal_sign',
                params: [message, accounts[0]],
            });
            this.walletStatusService.setSessionData({
                wallet: WalletEnum.TRUST_WALLET,
                walletAddress: accounts[0],
                signature: sign,
            });
            this.close();
            await this.goInvitation();
            this.connectingWallet = null;
        } catch (err: any) {
            this.commonService.error(err.message);
            this.connectingWallet = null;
        }
    }

    async getSdnSignMsg() {
        const msg = this.walletStatusService.getMessageToSign();
        this.walletStatusService.msgToSign = msg;

        return { message: msg };
    }

    getMessageToSign() {
        return `Login with this account\n\ntime: ${new Date().toISOString()}\n${this.walletStatusService.generateRandomHex(32)}`;
    }

    close() {
        this.modalRef?.triggerCancel();
        this.drawerRef?.close();
    }

    async goInvitation() {
        return new Promise(() => {
            const sub = this.walletStatusService.tokenStr$.pipe(filter(res => !!res)).subscribe(() => {
                if (this.walletStatusService.checkIfSignupCompleted()) {
                    sub.unsubscribe();
                    this.router.navigate(['/', 'my-profile']);
                    // window.location.reload();
                } else {
                    this.commonService.showEmailInvitationModal();
                }
            });
        });
    }
}
