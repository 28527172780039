<app-hexagon-clipper></app-hexagon-clipper>

<div class="flex flex-col relative h-[calc(var(--vh,1vh)*100)]" id="socialscanapp">
    <nz-layout>
        <nz-sider
            nzWidth="288px"
            nzTheme="light"
            class="max-md:!hidden overflow-visible z-20"
            nzCollapsible
            [nzCollapsedWidth]="76"
            [nzCollapsed]="sideBarCollapsed"
            [nzTrigger]="null"
        >
            <div
                class="h-full side-bar-container transition-all"
                (mouseenter)="onSideBarMouseEnter()"
                (mouseleave)="onSideBarMouseLeave()"
                [ngClass]="{
                    'w-[288px]': sideBarTempExpanded || !sideBarCollapsed,
                    'w-[76px]': !sideBarTempExpanded && sideBarCollapsed
                }"
            >
                <app-side-bar
                    [sideBarCollapsed]="sideBarCollapsed"
                    [sideBarTempExpanded]="sideBarTempExpanded"
                    (toggleSideBar)="onToggleSideBar()"
                    (dropdownVisibleChange)="dropdownVisible = $event"
                ></app-side-bar>
            </div>
        </nz-sider>

        <nz-layout>
            <!-- 移动端 header -->
            <nz-header
                class="md:hidden"
                [ngClass]="{
                    'max-md:!hidden':(isInMiningDaysPage && isInTg) || isInTgLandingPage
                }"
            >
                <ng-container *ngIf="!isInTg">
                    <div class="flex-1">
                        <div class="nav-icon" (click)="sidebarVisible = true">
                            <span nz-icon nzType="menu"></span>
                        </div>
                    </div>
                </ng-container>

                <span *ngIf="tgService.isOnlyTg" class="text-[40px]" nz-icon nzType="antd:v2-wallet" (click)="commonService.showLogInModal()"></span>
                <img *ngIf="!tgService.isOnlyTg" src="/assets/imgs/logo/v2-socialscan-dark.png" alt="SocialScan" class="h-[36px]" />

                <div class="flex-end-center gap-x-4 flex-1">
                    <div class="nav-icon relative">
                        <span
                            nz-icon
                            [nzType]="globalModal === 'notification' ? 'antd:v2-close' : 'antd:v2-notif'"
                            (click)="switchGlobalModal('notification')"
                        ></span>
                        <div *ngIf="hasUnreadNotifications " class="absolute w-2 h-2 rounded-full bg-[#FF462A] right-0 top-0"></div>
                    </div>

                    <div class="nav-icon">
                        <span nz-icon [nzType]="globalModal === 'search' ? 'antd:v2-close' : 'antd:v2-search'" (click)="switchGlobalModal('search')"></span>
                    </div>
                </div>
            </nz-header>

            <nz-content class="relative" [ngClass]="{'!p-0': isInMiningDaysPage || isInTgLandingPage}" #scrollContainer (scroll)="onScroll(scrollContainer)">
                <main><router-outlet></router-outlet></main>

                <!-- 移动端 notification -->
                <div
                    *ngIf="globalModal === 'notification'"
                    [@slideInUpOnEnter]
                    [@slideOutDownOnLeave]
                    class="absolute inset-0 w-full h-full bg-white z-50 overflow-y-auto p-4"
                    (wheel)="stopScroll($event)"
                    (touchmove)="stopScroll($event)"
                    [style.top.px]="scrollTop"
                >
                    <div class="sticky top-0 left-0">
                        <div class="font-semibold text-xl">Notifications</div>

                        <div class="divider"></div>
                    </div>

                    <app-notifications></app-notifications>
                </div>

                <!-- 移动端 search 组件 -->
                <div
                    *ngIf="globalModal === 'search'"
                    [@slideInUpOnEnter]
                    [@slideOutDownOnLeave]
                    class="absolute inset-0 w-full h-full bg-white z-50"
                    (wheel)="stopScroll($event)"
                    (touchmove)="stopScroll($event)"
                    [style.top.px]="scrollTop"
                >
                    <div class="p-4">
                        <input
                            nz-input
                            placeholder="Search address/Txn hash"
                            [(ngModel)]="searchInputStr"
                            class="!h-[44px] !rounded-3xl !bg-badge !border-none"
                        />

                        <app-search-result
                            [hidden]="!searchResultVisible"
                            [inputStr]="searchInputStr"
                            (fetchedData)="searchResultVisible = true"
                        ></app-search-result>
                    </div>
                </div>

                <!-- 移动端 一键返回顶部 -->
                <div *ngIf="scrollToTopVisible" class="fixed bottom-16 right-6 z-50 md:hidden">
                    <button
                        class="!w-10 !h-10 !border-none flex-center-center hover:!border !shadow-[0px_6px_16px_0px_rgba(0,0,0,0.08),0px_3px_6px_-4px_rgba(0,0,0,0.12),0px_9px_28px_8px_rgba(0,0,0,0.05)]"
                        nz-button
                        nzShape="round"
                        (click)="scrollToTop()"
                    >
                        <span nz-icon class="!text-3xl" nzType="antd:v2-arrow-up"></span>
                    </button>
                </div>
            </nz-content>

            <nz-footer
                *ngIf="isInTg"
                [ngClass]="{
                    'max-md:!hidden':isInMiningDaysPage || isInTgLandingPage
                }"
            >
                <app-profile-footer></app-profile-footer>
            </nz-footer>
        </nz-layout>
    </nz-layout>

    <!-- 移动端 H5 侧拉 -->
    <nz-drawer
        [nzClosable]="false"
        [nzVisible]="sidebarVisible"
        nzPlacement="left"
        [nzTitle]="null"
        (nzOnClose)="sidebarVisible = false"
        [nzWidth]="320"
        [nzBodyStyle]="{
            'background': 'linear-gradient(to bottom, #c9fffc, #ffeba3)',
            'padding': '0'
        }"
    >
        <ng-container *nzDrawerContent>
            <app-side-bar></app-side-bar>
        </ng-container>
    </nz-drawer>
</div>

<ng-template #nzIndicatorTpl>
    <span class="ant-spin-dot !text-[40px]">
        <span nz-icon [nzType]="'loading'"></span>
    </span>
</ng-template>

<ng-template #nzEmptyTpl>
    <div class="flex-center-center h-full flex-col">
        <img src="/assets/imgs/no-data/no-data.png" alt="empty" class="w-[100px]" />
        <div class="text-sm text-sub-text">No data</div>
    </div>
</ng-template>
