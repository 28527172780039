import { Injectable } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { TelegramService } from '@src/app/services/telegram.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard {
    constructor(private walletStatusService: WalletStatusService, private commonService: CommonService, private tgService: TelegramService) {}

    async canActivate() {
        if (!this.walletStatusService.isWalletConnected && !this.tgService.isInTelegramWebApp) {
            return false;
        }

        if (this.walletStatusService.userProfile) {
            return true;
        }

        return this.walletStatusService.getWalletProfile();
    }

    // checkIfSignupCompleted(token: string) {
    //     if (!token) return false;
    //     try {
    //         const decodedInfo = this.walletStatusService.decodeJWT(token);
    //         return decodedInfo?.registration_complete;
    //     } catch (err) {
    //         return false;
    //     }
    // }
}
