import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ProfileGuard } from '@shared/guards/profile.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
    },
    {
        path: 'home',
        loadComponent: () => import('./standalone-components/home/home.component').then(m => m.HomeComponent),
    },

    {
        path: 'referral',
        canActivate: [() => inject(AuthGuard).canActivate()],
        loadComponent: () => import('./standalone-components/referral/referral.component').then(c => c.ReferralComponent),
    },
    {
        path: 'transaction',
        loadChildren: () => import('./modules/transaction/transaction.module').then(m => m.TransactionModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: 'address',
        loadChildren: () => import('./modules/address/address.module').then(m => m.AddressModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },

    {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module').then(m => m.MyProfileModule),
        canActivateChild: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },

    {
        path: 'my-subscriptions',
        loadComponent: () => import('./standalone-components/my-subscriptions/my-subscriptions.component').then(m => m.MySubscriptionsComponent),
        canActivateChild: [() => inject(AuthGuard).canActivate(), () => inject(ProfileGuard).canActivate()],
    },
    {
        path: 'policy',
        loadChildren: () => import('./modules/policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'hemera',
        loadChildren: () => import('./modules/hemera/hemera.module').then(m => m.HemeraModule),
    },
    {
        path: 'meme-dashboard',
        loadChildren: () => import('./modules/meme-dashboard/meme-dashboard.module').then(m => m.MemeDashboardModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            scrollPositionRestoration: 'enabled',
        }),
    ],

    exports: [RouterModule],
})
export class AppRoutingModule {}
