<div class="h-20 w-full flex justify-between items-stretch border-t border-line z-30">
    <div class="nav-tab" [routerLink]="['/','hemera','agent','mining-days']" [routerLinkActive]="'active'">
        <span class="text-3xl" nz-icon nzType="antd:v2-game"></span>
        <span>Mining</span>
    </div>

    <div class="nav-tab" [routerLink]="['/','referral']" [routerLinkActive]="'active'">
        <span class="text-3xl" nz-icon nzType="antd:v2-share"></span>
        <span>Friends</span>
    </div>
    <div class="nav-tab" [routerLink]="['/','my-profile']" [routerLinkActive]="'active'">
        <span class="text-3xl" nz-icon nzType="antd:v2-user"></span>
        <span>Profile</span>
    </div>
</div>
