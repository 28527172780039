<nav
    class="flex flex-col h-full gap-y-10 py-10 whitespace-nowrap px-[18px]"
    [ngClass]="{'min-w-[240px]': !isSideBarCollapsedNow,'collapsed':isSideBarCollapsedNow}"
>
    <div [ngClass]="{'flex-between-center': !isSideBarCollapsedNow, 'flex-center-center': isSideBarCollapsedNow}">
        <img *ngIf="isSideBarCollapsedNow" src="/assets/imgs/logo/v2-socialscan-square-logo.png" alt="logo" class="w-auto h-[37px]" />
        <img *ngIf="!isSideBarCollapsedNow" src="/assets/imgs/logo/v2-socialscan.png" alt="logo" class="w-auto h-[37px]" />

        <img
            *ngIf="!isSideBarCollapsedNow"
            src="/assets/imgs/v2-collapse.png"
            alt=""
            [nz-tooltip]="sideBarCollapsed ? 'Expand Menu' : 'Collapse Menu'"
            class="w-auto h-5 cursor-pointer opacity-30 hover:opacity-100 max-md:hidden"
            [ngClass]="{'rotate-180':sideBarCollapsed}"
            [nzTooltipPlacement]="'bottom'"
            (click)="handleToggleSideBar()"
        />
    </div>
    <div class="flex-1 min-h-0 flex flex-col gap-y-4">
        <div
            class="hidden md:block"
            nz-dropdown
            [nzDropdownMenu]="searchDropdown"
            [nzPlacement]="'bottomLeft'"
            [(nzVisible)]="searchDropDownVisible"
            [nzTrigger]="null"
            [nzOverlayClassName]=""
            [nzOverlayStyle]="{
                'padding': '0 16px 16px',
                'border-radius': '8px',
                'background': '#fff',
                'box-shadow':'0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)'
            }"
        >
            <ng-template #prefixTemplateUser><span nz-icon nzType="antd:v2-search" class="text-2xl" nzTheme="outline"></span></ng-template>

            <nz-input-group [nzPrefix]="prefixTemplateUser" class="!p-2">
                <input type="text" nz-input placeholder="Search address/Txn hash" [(ngModel)]="searchVal" />
            </nz-input-group>
        </div>

        <nz-dropdown-menu #searchDropdown="nzDropdownMenu">
            <app-search-result [inputStr]="searchVal" (fetchedData)="searched()"></app-search-result>
        </nz-dropdown-menu>

        <div class="overflow-y-auto flex-1 small-scrollbar space-y-1 overflow-x-hidden w-[calc(100%+10px)] pr-2.5">
            <div [ngClass]="['nav-item','!cursor-default']">
                <span nz-icon nzType="antd:v2-analysis" class="text-xl" nzTheme="outline"></span>
                <span class="nav-text">UDF Dashboard</span>
            </div>

            <div
                *ngIf="!isSideBarCollapsedNow"
                [ngClass]="['nav-sub-item','has-link']"
                [routerLink]="['/']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                <div class="w-5"></div>
                <span class="nav-text">Ethereum</span>
            </div>

            <div
                *ngIf="!isSideBarCollapsedNow"
                [ngClass]="['nav-sub-item','has-link']"
                [routerLink]="['/','coin-dashboard','fbtc']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: false }"
            >
                <div class="w-5"></div>
                <span class="nav-text">FBTC</span>
            </div>

            <div *ngIf="!isSideBarCollapsedNow" [ngClass]="['nav-sub-item','has-link']" [routerLink]="['/','meme-dashboard']" [routerLinkActive]="'active'">
                <div class="w-5"></div>
                <div class="flex-start-center gap-x-1.5">
                    <span>MEME Smart Buy</span>
                </div>
            </div>

            <div *ngIf="!isSideBarCollapsedNow" [ngClass]="['nav-sub-item','disabled']">
                <div class="w-5"></div>
                <div class="flex-start-center gap-x-1.5">
                    <span>Story</span>
                    <div class="coming-soon">Coming soon</div>
                </div>
            </div>

            <div
                [ngClass]="['nav-item','has-link']"
                [routerLink]="['/','hemera','agent','mining-days']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                <span nz-icon nzType="antd:v2-game" class="text-xl" nzTheme="outline"></span>
                <span>UDF Mining Game</span>
            </div>

            <div [ngClass]="['nav-item','!cursor-default']">
                <span nz-icon nzType="antd:v2-robot" class="text-xl" nzTheme="outline"></span>
                <div class="flex-start-center gap-x-1.5 nav-text">
                    <span>AI Agent</span>
                    <div class="coming-soon">Coming soon</div>
                </div>
            </div>

            <div *ngIf="!isSideBarCollapsedNow" [ngClass]="['nav-sub-item','disabled']">
                <div class="w-5"></div>
                <div class="flex-start-center gap-x-1.5 nav-text">
                    <span>MEME Hunter Agent</span>
                    <!-- <div class="coming-soon">Coming soon</div> -->
                </div>
            </div>

            <div class="divider !my-4"></div>

            <div [ngClass]="['nav-item','has-link']" [routerLink]="['/my-profile']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }">
                <span nz-icon nzType="antd:v2-user" class="text-xl" nzTheme="outline"></span>
                <span>Profile</span>
            </div>

            <div [ngClass]="['nav-item','has-link']" [routerLink]="['/referral']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <span nz-icon nzType="antd:v2-share" class="text-xl" nzTheme="outline"></span>
                <span>Referral</span>
            </div>
        </div>
    </div>
    <div class="space-y-8">
        <div>
            <!-- Get Started -->
            <ng-container *ngIf="!commonService.isMobile">
                <button
                    *ngIf="walletStatusService.isWalletConnected && percentage !== 100 && !isSideBarCollapsedNow"
                    nz-button
                    class="get-started-btn"
                    nz-popover
                    [(nzPopoverVisible)]="getStartedVisible"
                    [nzPopoverTitle]="stepTitleTemplate"
                    [nzPopoverContent]="stepTemplate"
                    nzPopoverPlacement=""
                    nzPopoverTrigger="click"
                >
                    <app-progress-circle [percentage]="percentage" class="w-[24px] h-[24px]"></app-progress-circle>
                    <div>Get Started</div>
                    <span nz-icon nzType="caret-right" class="text-sm" nzTheme="outline"></span>
                </button>
            </ng-container>

            <ng-container *ngIf="commonService.isMobile">
                <button
                    *ngIf="walletStatusService.isWalletConnected && percentage !== 100 && !isSideBarCollapsedNow"
                    nz-button
                    class="get-started-btn md:hidden"
                    (click)="getStartedVisible = true"
                >
                    <app-progress-circle [percentage]="percentage" class="w-[24px] h-[24px]"></app-progress-circle>
                    <div>Get Started</div>
                    <span nz-icon nzType="caret-right" class="text-sm" nzTheme="outline"></span>
                </button>
            </ng-container>

            <div class="h-4"></div>

            <button
                *ngIf="!walletStatusService.isWalletConnected"
                nz-button
                nzType="primary"
                class="connect-wallet-btn"
                [ngClass]="{'!h-10': isSideBarCollapsedNow}"
                nzSize="large"
                (click)="toggleWalletConnection()"
            >
                <ng-container *ngIf="!isSideBarCollapsedNow">
                    <div class="w-fit colorful-text">Connect Wallet</div>
                    <img src="/assets/imgs/v2-long-right-arrow-colorful.png" alt="go" class="w-6 h-6" />
                </ng-container>
                <ng-container *ngIf="isSideBarCollapsedNow">
                    <img src="/assets/imgs/icon/wallet-v2.png" class="h-auto w-5" alt="" />
                </ng-container>
            </button>

            <ng-container *ngIf="walletStatusService.isWalletConnected">
                <button
                    *ngIf="!isSideBarCollapsedNow"
                    nzGhost
                    nz-button
                    class="connect-wallet-btn !border-none !shadow-none !p-0 has-link"
                    nzSize="large"
                    [routerLink]="['/my-profile']"
                >
                    <app-hexagon-img class="w-11" [imgUrl]="userService.userImg"></app-hexagon-img>
                    <div class="flex-1 text-left">{{ userService.userName | address}}</div>
                    <div
                        #moreDropdown
                        class="w-10 h-10 rounded-lg hover:bg-black hover:bg-opacity-6 flex-center-center"
                        nz-dropdown
                        nzTrigger="click"
                        [nzDropdownMenu]="menu"
                        [(nzVisible)]="dropdownVisible"
                        (nzVisibleChange)="onDropdownVisibleChange($event)"
                        (click)="$event.stopPropagation();$event.preventDefault()"
                    >
                        <span *ngIf="!walletStatusService.disconnecting" nz-icon nzType="antd:v2-more" class="text-2xl text-black" nzTheme="outline"></span>
                    </div>

                    <span *ngIf="walletStatusService.disconnecting" class="text-white text-2xl" nz-icon nzType="loading" nzTheme="outline"></span>
                </button>

                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu class="!w-[252px]">
                        <li nz-menu-item (click)="settingsModalVisible = true">
                            <div class="flex-start-center gap-x-2 h-8">
                                <span nz-icon nzType="setting"></span>
                                <span class="text-sm">Settings</span>
                            </div>
                        </li>
                        <li nz-menu-item (click)="toggleWalletConnection()">
                            <div class="flex-start-center h-8">
                                <span nz-icon nzType="antd:v2-logout" class="mr-2"></span>
                                <span class="text-sm">
                                    Logout
                                    <span
                                        *ngIf="walletStatusService.disconnecting"
                                        class="text-white text-sm ml-1"
                                        nz-icon
                                        nzType="loading"
                                        nzTheme="outline"
                                    ></span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </nz-dropdown-menu>

                <app-hexagon-img *ngIf="isSideBarCollapsedNow" class="w-12 -ml-1" [imgUrl]="userService.userImg"></app-hexagon-img>
            </ng-container>
        </div>

        <div class="space-y-4">
            <div class="flex-between-center gap-5" [ngClass]="{'flex-col': isSideBarCollapsedNow}">
                <div class="flex-start-center gap-5" [ngClass]="{'flex-col': isSideBarCollapsedNow}">
                    <a href="https://discord.gg/ZqYx5dXsAh" target="_blank" class="social-icon">
                        <span nz-icon nzType="antd:discord" nzTheme="fill" class="text-lg"></span>
                    </a>

                    <a href="https://twitter.com/socialscan_io" target="_blank" class="social-icon">
                        <span nz-icon nzType="antd:x" nzTheme="fill" class="text-base"></span>
                    </a>

                    <a href="https://t.me/SocialScanAnn" target="_blank" class="social-icon">
                        <span nz-icon nzType="antd:telegram" nzTheme="fill" class="text-base"></span>
                    </a>
                </div>

                <button
                    *ngIf="walletStatusService.isWalletConnected"
                    nz-button
                    nzShape="round"
                    nzSize="small"
                    nz-popover
                    class="!w-8 !h-8 !border-none max-md:hidden relatiiive"
                    [nzPopoverTitle]="notifiTitleTemplate"
                    [nzPopoverContent]="notifTemplate"
                    nzPopoverPlacement="right"
                    nzPopoverTrigger="click"
                    [nzPopoverOverlayStyle]="{ maxWidth: '460px' }"
                    [nzPopoverArrowPointAtCenter]="false"
                    [(nzPopoverVisible)]="notfiVisible"
                >
                    <span nz-icon nzType="antd:v2-notif" class="text-lg"></span>
                    <div *ngIf="hasUnreadNotifications " class="absolute w-2 h-2 rounded-full bg-[#FF462A] right-0 top-0"></div>
                </button>
            </div>

            <div *ngIf="!isSideBarCollapsedNow" class="w-full flex-between-center text-xs">
                <a
                    href="https://drive.google.com/drive/folders/11wmioe_npIbiMoCzIgGCvHhuGfYRoq5y?usp=sharing"
                    class="text-black text-opacity-30"
                    target="_blank"
                >
                    Brand Kit
                </a>
                <div class="h-4 w-[1px] bg-line"></div>
                <a href="https://www.thehemera.com/blogs" class="text-black text-opacity-30" target="_blank">Blog</a>
                <div class="h-4 w-[1px] bg-line"></div>
                <a href="https://www.thehemera.com/about-us" class="text-black text-opacity-30" target="_blank">About Us</a>
            </div>
        </div>
    </div>
</nav>

<!-- Templates -->
<ng-template #stepTemplate>
    <div>
        <div *ngFor="let step of steps; let i = index" class="flex-start-center gap-x-2.5 text-sm py-1">
            <span nz-icon class="text-base" [nzType]="completedSteps[i] ? 'antd:v2-checked' : 'antd:v2-unchecked'"></span>
            <span>{{ i + 1 }}. {{ step }}</span>
        </div>
    </div>
</ng-template>

<ng-template #stepTitleTemplate>
    <div class="w-full flex-between-center">
        <div>{{stepTitle}}</div>

        <span nz-icon nzType="antd:v2-close" class="text-base !text-[#999]" (click)="getStartedVisible = false"></span>
    </div>
</ng-template>

<ng-template #notifTemplate>
    <div class="max-h-[500px] overflow-y-auto small-scrollbar text-sm">
        <app-notifications></app-notifications>
    </div>
</ng-template>

<ng-template #notifiTitleTemplate>
    <div class="w-full flex-between-center">
        <div>Notifications</div>

        <span nz-icon nzType="antd:v2-close" class="text-base !text-[#999]" (click)="notfiVisible = false"></span>
    </div>
</ng-template>

<nz-modal
    *ngIf="commonService.isMobile"
    [(nzVisible)]="getStartedVisible"
    [nzTitle]="stepTitle"
    (nzOnCancel)="getStartedVisible = false"
    [nzFooter]="null"
    [nzContent]="stepTemplate"
    nzCentered
>
</nz-modal>

<nz-modal [(nzVisible)]="settingsModalVisible" nzTitle="Settings" (nzOnCancel)="settingsModalVisible = false" [nzFooter]="null" nzCentered>
    <ng-container *nzModalContent>
        <div class="flex-between-center">
            <span class="text-base font-semibold">Allow Followers</span>
            <nz-switch
                [ngModel]="walletStatusService.userProfile.allow_follower"
                (ngModelChange)="toggleAllowFollowers($event)"
                [disabled]="updatingAllowFollowers"
            ></nz-switch>
        </div>
    </ng-container>
</nz-modal>
