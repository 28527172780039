<!-- used for hexagon-img -->
<svg style="position: fixed; left: -100%; top: -100%">
    <defs>
        <clipPath id="hexagonClip">
            <path
                d="M152.22,51.34l-55-31.66a16.46,16.46,0,0,0-16.59.09L24.36,53a16.47,16.47,0,0,0-8.1,14l-.71,65.2a16.41,16.41,0,0,0,8.23,14.44l55,31.66a16.46,16.46,0,0,0,16.59-.09L151.63,145a16.46,16.46,0,0,0,8.1-14l.72-65.2A16.42,16.42,0,0,0,152.22,51.34Z"
            ></path>
        </clipPath>

        <clipPath id="clipImgRounded" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.5" />
        </clipPath>
    </defs>
</svg>
