import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TelegramService } from '@src/app/services/telegram.service';
import { SharedModule } from '@src/app/shared/shared.module';

@Component({
    selector: 'app-profile-footer',
    templateUrl: './profile-footer.component.html',
    styleUrls: ['./profile-footer.component.less'],
    standalone: true,
    imports: [CommonModule, SharedModule],
})
export class ProfileFooterComponent {
    @Input() navTab: 'home' | 'profile' | 'mining' = 'home';

    constructor(private router: Router, private tgService: TelegramService) {}

    changeNavTab(tab: 'home' | 'profile' | 'mining') {
        if (tab === 'mining') {
            this.router.navigateByUrl('/hemera/agent/mining-days');
        } else {
            this.router.navigate([], { fragment: tab });
        }
    }
}
