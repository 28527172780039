import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GraphicComponentOption, LegendComponentOption, ScatterSeriesOption, VisualMapComponentOption } from 'echarts';

import {
    BarChart,
    BarSeriesOption,
    LineChart,
    LineSeriesOption,
    PieChart,
    PieSeriesOption,
    ScatterChart,
    TreemapChart,
    TreemapSeriesOption,
} from 'echarts/charts';
import {
    GraphicComponent,
    GridComponent,
    GridComponentOption,
    LegendComponent,
    TitleComponent,
    TitleComponentOption,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
echarts.use([
    GridComponent,
    LineChart,
    PieChart,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    ToolboxComponent,
    VisualMapComponent,
    SVGRenderer,
    ScatterChart,
    GraphicComponent,
    TreemapChart,
]);

export type EchartsOption = echarts.ComposeOption<
    | GridComponentOption
    | LineSeriesOption
    | PieSeriesOption
    | BarSeriesOption
    | TitleComponentOption
    | VisualMapComponentOption
    | ScatterSeriesOption
    | GraphicComponentOption
    | LegendComponentOption
    | TreemapSeriesOption
>;
export { echarts };

export const COLORS = [
    '#FEE384',
    '#CBF484',
    '#96EEDB',
    '#96CCF3',
    '#FFB5D4',
    '#D1A7E1',
    '#FFB3C1',
    '#F3C763',
    '#DAD83E',
    '#FFA340',
    '#E781F2',
    '#FF8DAF',
    '#B5AFFF',
    '#62E0F1',
    '#5AA6F3',
    '#4482ED',
    '#A775F6',
    '#FFC934',
    '#FFA310',
    '#97ED61',
    '#56DE1C',
    '#46C5EB',
    '#678DCF',
    '#69AC93',
    '#A8D8FF',
    '#A3D2C1',
    '#2990FF',
    '#8E7299',
    '#A4717A',
    '#AD9150',
];

export const SYMBOL_OPTIONS = [
    {
        symbol: 'diamond',
        symbolSize: 12,
        emphasis: {
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
            },
        },
    },
    {
        symbol: 'rect',
        symbolSize: 12,
        emphasis: {
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
            },
        },
    },
    {
        symbol: 'triangle',
        symbolSize: 12,
        emphasis: {
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
            },
        },
    },
    {
        symbol: 'circle',
        symbolSize: 12,
        emphasis: {
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
            },
        },
    },
];

@Directive({
    selector: '[appEcharts]',
})
export class EchartsDirective implements AfterViewInit, OnChanges {
    @Input() initialOption: EchartsOption;
    @Input() optionToBeUpdate: EchartsOption & { notMerge?: boolean };
    @Input() theme: 'light' | 'dark' = 'light';

    charts: echarts.EChartsType;
    private chartInitialized = false;

    private legendHeight = 0;

    constructor(private el: ElementRef) {}

    @Output() legendHeightChange = new EventEmitter<number>();

    @HostListener('window:resize')
    resizeChart() {
        this.charts?.resize();
    }

    ngOnChanges(change: SimpleChanges) {
        if (!change['optionToBeUpdate']?.currentValue) return;
        const needMerge = !!this.optionToBeUpdate?.notMerge;
        delete this.optionToBeUpdate?.notMerge;
        const newOption = this.optionToBeUpdate;

        if (this.chartInitialized) {
            this.charts.setOption(newOption, needMerge);
        } else {
            const func = () => {
                if (this.charts) {
                    this.charts.setOption(newOption, needMerge);
                } else {
                    setTimeout(() => {
                        func();
                    }, 10);
                }
            };

            func();
        }
    }

    showLoading() {
        this.charts.showLoading();
    }

    hideLoading() {
        this.charts.hideLoading();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.charts = echarts.init(this.el.nativeElement, this.theme, { renderer: 'svg' });
            this.charts.setOption(this.initialOption);
            this.chartInitialized = true;

            this.charts.on('rendered', () => {
                // @ts-ignore
                const legendModel = this.charts.getModel().getComponent('legend');
                if (!legendModel) return;
                // @ts-ignore
                const legendView = this.charts.getViewOfComponentModel(legendModel);
                const { height } = legendView.group.getBoundingRect();

                if (height !== this.legendHeight) {
                    this.legendHeight = height;
                    this.legendHeightChange.emit(this.legendHeight);
                }
            });
        }, 10);
    }
}
