/* eslint-disable max-len */
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';

const spinnerTpl = `
    <svg viewBox="0 0 1024 1024" fill="currentColor" width="100%" height="100%">
        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
    </svg>
`;

@Directive({
    selector: '[appLoading]',
})
export class LoadingDirective implements AfterViewInit, OnChanges {
    @Input() loading: boolean;
    @Input() maskVisible: boolean;

    private boxNode: HTMLElement;

    constructor(private el: ElementRef<HTMLDivElement>, @Inject(DOCUMENT) private document: Document) {}

    ngOnChanges(e: SimpleChanges) {
        if (!e?.['loading'].firstChange) {
            if (this.loading) {
                this.showLoading();
            } else {
                this.hideLoading();
            }
        }
    }

    ngAfterViewInit() {
        if (this.loading) {
            this.showLoading();
        }
    }

    showLoading() {
        if (!this.el.nativeElement.style.position || this.el.nativeElement.style.position === 'static') {
            this.el.nativeElement.style.position = 'relative';
        }

        this.boxNode = this.el.nativeElement.querySelector('.loading-directive-div') || this.document.createElement('div');
        this.boxNode.classList.add('loading-directive-div');
        this.boxNode.style.width = '100%';
        this.boxNode.style.height = '100%';
        this.boxNode.style.display = 'flex';
        this.boxNode.style.justifyContent = 'center';
        this.boxNode.style.alignItems = 'center';
        this.boxNode.style.position = 'absolute';
        this.boxNode.style.left = '0';
        this.boxNode.style.top = '0';
        this.boxNode.style.zIndex = '999';
        if (this.maskVisible) {
            this.boxNode.style.background = 'rgba(255,255,255,0.1)';
        }

        const imgContainer = this.document.createElement('div');
        imgContainer.innerHTML = spinnerTpl;
        imgContainer.style.width = '40px';
        imgContainer.style.height = '40px';
        imgContainer.style.maxWidth = '100%';
        imgContainer.style.maxHeight = '100%';
        imgContainer.style.objectFit = 'contain';
        imgContainer.className = 'animate-spin';

        this.boxNode.appendChild(imgContainer);

        this.el.nativeElement.append(this.boxNode);
    }

    hideLoading() {
        this.boxNode.style.opacity = '0';
        setTimeout(() => {
            this.boxNode.remove();
        }, 500);
    }
}
