export function getTGDataFromWebApp() {
    return window.Telegram.WebApp.initDataUnsafe;
    // return {
    //     query_id: 'AAGzhUlKAgAAALOFSUqqU3N9',
    //     user: {
    //         id: 5541299635,
    //         first_name: 'Lin',
    //         last_name: '',
    //         username: 'linxuan_li_96',
    //         language_code: 'en',
    //         allows_write_to_pm: true,
    //         photo_url: 'https://t.me/i/userpic/320/tBtey2iUDEYCVeNtIanGYbkqSR1miKuMFjl1WZRCFV-KJETBjNgX_yiRFtCkq734.svg',
    //     },
    //     auth_date: '1732534790',
    //     signature: 'GAr9fwtO-HkP9fRlF1-QLy7tuI5X_8SOPeSJqZrhw_AV3CBlDOQUUGq9UxSrGi8FOomL_KCXCuxRn6vy7833AA',
    //     hash: '8217713b684ebd604ae73a7763e70a9101f738cb31539783a3c4d5cfabc44abc',
    // };
}

export function getTGSafeDataFromWebApp() {
    return window.Telegram.WebApp.initData;
    // return 'query_id=AAGzhUlKAgAAALOFSUr3FBvd&user=%7B%22id%22%3A5541299635%2C%22first_name%22%3A%22Lin%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22linxuan_li_96%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FtBtey2iUDEYCVeNtIanGYbkqSR1miKuMFjl1WZRCFV-KJETBjNgX_yiRFtCkq734.svg%22%7D&auth_date=1732606024&signature=wA_M_CZ8a67ASSs00QfqO6Fpq2ah_lnNPnggxdr-amnPtZv3iytL20brOC356zqkie7aOkcxvtP8h3lEv5vWBw&hash=5327f87dcc26e8eccb6b936f341bd6b1b216f7a3fa25630e1b66b9a3fab65e6e';
}
