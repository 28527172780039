import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortOrder } from '../../types/ss-table.type';

@Component({
    selector: 'app-table-sorter',
    templateUrl: './table-sorter.component.html',
    styleUrls: ['./table-sorter.component.less'],
})
export class TableSorterComponent {
    @Input() sortOrder: SortOrder = null;
    @Output() sortOrderChange = new EventEmitter<SortOrder>();

    sortOrderClicked() {
        if (!this.sortOrder) {
            this.sortOrder = 'ascend';
        } else if (this.sortOrder === 'ascend') {
            this.sortOrder = 'descend';
        } else {
            this.sortOrder = null;
        }

        this.sortOrderChange.emit(this.sortOrder);
    }
}
