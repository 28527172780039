import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-hexagon-clipper',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './hexagon-clipper.component.html',
    styleUrls: ['./hexagon-clipper.component.less'],
})
export class HexagonClipperComponent {}
