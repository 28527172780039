import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@src/app/services/notification.service';
import { SharedModule } from '@src/app/shared/shared.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [CommonModule, SharedModule, NzSkeletonModule, NzBadgeModule],
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
})
export class NotificationsComponent implements OnInit {
    get notifications() {
        return this.notificationService.messages;
    }

    constructor(public notificationService: NotificationService) {}

    ngOnInit() {
        this.markAllAsRead();
    }

    markAllAsRead() {
        this.notifications
            ?.filter(n => !n.is_read)
            .forEach(n => {
                n.is_read = true;
                this.notificationService.markMessageAsRead(n.id);
            });
    }
}
