import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { environment } from '@src/environments/environment';
import { slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { filter } from 'rxjs';
import { CommonService } from './services/common.service';
import { EthersRequestService } from './services/ethers-request.service';
import { GtagService } from './services/gtag.service';
import { NotificationService } from './services/notification.service';
import { RouterChangeMonitorService } from './services/router-change-monitor.service';
import { TelegramService } from './services/telegram.service';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: any;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [slideInUpOnEnterAnimation({ duration: 300 }), slideOutDownOnLeaveAnimation({ duration: 300 })],
})
export class AppComponent implements AfterViewInit, OnInit {
    @ViewChild('scrollContainer') scrollContainer!: { elementRef: ElementRef<any> };
    @ViewChild('nzIndicatorTpl', { static: true }) nzIndicator!: TemplateRef<void>;
    @ViewChild('nzEmptyTpl', { static: true }) nzEmpty!: TemplateRef<string>;

    sidebarVisible = false;

    sideBarCollapsed = false;

    sideBarTempExpanded = false;

    globalModal: 'notification' | 'search' = null;

    scrollTop = 0;

    searchInputStr = '';

    searchResultVisible = false;

    isInMiningDaysPage = false;

    isInTgLandingPage = false;

    isToggling = false;

    dropdownVisible = false;

    scrollToTopVisible = false;

    get isInTg() {
        return this.tgService.isInTelegramWebApp;
    }

    get notifications() {
        return this.notificationService.messages;
    }

    get hasUnreadNotifications() {
        return this.notifications?.some(n => !n.is_read);
    }

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private gtagService: GtagService,
        private routerMonitor: RouterChangeMonitorService,
        private ethersRequest: EthersRequestService,
        private notificationService: NotificationService,
        public commonService: CommonService,
        public tgService: TelegramService,
        private nzConfigService: NzConfigService
    ) {}

    onScroll(ele: any) {
        this.scrollTop = ele.elementRef.nativeElement.scrollTop;

        // 如果超过一屏幕的高度，就展示
        this.scrollToTopVisible = this.scrollTop > window.innerHeight;
    }

    ngOnInit() {
        this.setAntdConfig();

        this.sideBarCollapsed = localStorage.getItem('sideBarCollapsed') === 'collapsed';

        if (environment.production && isPlatformBrowser(this.platformId)) {
            this.gtagService.initializeGtag();
        }

        this.ethersRequest.initProvider();

        this.routerMonitor.setupRouterEvents();

        // 全局设置页面前进，后退时的恢复原先位置的动画
        this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(route => {
            const typedRoute: Scroll = route as Scroll;

            const currentPath = typedRoute.routerEvent?.url?.split('#')?.[0];
            const previousPath = this.routerMonitor.previousPath?.split('#')?.[0];

            if (currentPath === previousPath) {
                return;
            }

            if (typedRoute.position) {
                // Back
                setTimeout(() => {
                    this.scrollContainer.elementRef.nativeElement.scrollTo({
                        top: typedRoute.position[1],
                        left: typedRoute.position[0],
                        behavior: 'auto',
                    });
                }, 10);
            } else {
                // Forward
                setTimeout(() => {
                    this.scrollContainer.elementRef.nativeElement.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'auto',
                    });
                }, 10);
            }
        });

        // 监听路由事件
        this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(event => {
            // 设置页面信息
            this.commonService.setPageInfo();

            // 页面跳转关闭搜索框
            this.globalModal = null;

            // 关闭drawer
            this.sidebarVisible = false;

            // If Day Mining Page， collapsed side bar
            if (event?.url.startsWith('/hemera/agent/mining-days') || event?.url.startsWith('/hemera/mining-rules')) {
                this.isInMiningDaysPage = true;
            } else {
                this.isInMiningDaysPage = false;
            }

            // If TG Landing Page
            if (event?.url.startsWith('/tg-landing')) {
                this.isInTgLandingPage = true;
            } else {
                this.isInTgLandingPage = false;
            }
        });
    }

    ngAfterViewInit() {
        // Setup vh on mobile
        this.setupVH();
    }

    setupVH() {
        const setHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setHeight();
        window.addEventListener('resize', setHeight);
    }

    switchGlobalModal(modal: 'notification' | 'search') {
        if (this.globalModal === modal) {
            this.globalModal = null;
        } else {
            this.globalModal = modal;
        }
    }

    stopScroll(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onSideBarMouseEnter() {
        // 只在永久收起状态时，且不是正在切换状态时，且 dropdown 未展开时才触发临时展开
        if (this.sideBarCollapsed && !this.isToggling && !this.dropdownVisible) {
            this.sideBarTempExpanded = true;
        }
    }

    onSideBarMouseLeave() {
        // 只在永久收起状态时，且 dropdown 未展开时才触发临时收起
        if (this.sideBarCollapsed && !this.dropdownVisible) {
            this.sideBarTempExpanded = false;
        }
    }
    onToggleSideBar() {
        this.isToggling = true; // 添加一个标记，表示正在切换状态
        this.sideBarCollapsed = !this.sideBarCollapsed;
        this.sideBarTempExpanded = false; // 立即清除临时展开状态

        localStorage.setItem('sideBarCollapsed', this.sideBarCollapsed ? 'collapsed' : 'expanded');

        // 延迟重置 isToggling 标记
        setTimeout(() => {
            this.isToggling = false;
            // Dispatch window resize
            window.dispatchEvent(new Event('resize'));
        }, 500);
    }

    scrollToTop() {
        this.scrollContainer.elementRef.nativeElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    setAntdConfig() {
        this.nzConfigService.set('table', { nzLoadingIndicator: this.nzIndicator });
        this.nzConfigService.set('empty', { nzDefaultEmptyContent: this.nzEmpty });
    }
}
