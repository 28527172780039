export enum WalletEnum {
    WALLET_CONNECT = 'wallet_connect',
    META_MASK = 'meta_mask',
    COINBASE = 'coinbase',
    TRUST_WALLET = 'trust_wallet',
}

export enum ChainEnum {
    ETHEREUM_FULL = 'ethereum',
    ETHEREUM = 'ethereum',
    ETHEREUM_SHORT = 'eth',
    POLYGON = 'polygon',
    MANTLE = 'mantle',
    BASE = 'base',
    MANTA_PACIFIC = 'manta-pacific',
    OP = 'optimism',
    ARBITRUM = 'arbitrum',
    AVALANCHE_C = 'avalanche-c',
    POLYGON_ZKEVM = 'polygon-zkevm',
    LINEA = 'linea',
    LINEA_TESTNET = 'linea-testnet',
    BLAST = 'blast',
    BNB_TESTNET = 'bnb-testnet',
    BSC = 'bsc',
    BOB = 'bob',
}

export interface ChainInfo {
    key: ChainEnum;
    name: string;
    imgUrl: string;
    wallets: WalletEnum[];
    explorerUrl: string;
    infoUsedConnectWallet?: {
        chainId: string;
        rpcUrls: string[];
        chainName: string;
        nativeCurrency?: {
            symbol: string;
            decimals: number;
        };
    };
}

export const WalletsEnableConnect = [
    {
        displayName: 'MetaMask',
        name: WalletEnum.META_MASK,
        img: '/assets/imgs/wallets/metamask.png',
    },
    {
        displayName: 'Trust Wallet',
        name: WalletEnum.TRUST_WALLET,
        img: '/assets/imgs/wallets/trust-wallet.svg',
    },
    {
        displayName: 'Wallet Connect',
        name: WalletEnum.WALLET_CONNECT,
        img: '/assets/imgs/wallets/walletconnect.png',
    },
];

export const WalletIconMap = new Map(WalletsEnableConnect.map(item => [item.name, item.img]));

export const AllChains = [ChainEnum.ETHEREUM, ChainEnum.LINEA, ChainEnum.ARBITRUM, ChainEnum.OP, ChainEnum.BASE, ChainEnum.MANTLE];

export const ChainInfoMap: Map<ChainEnum, ChainInfo> = new Map([
    [
        ChainEnum.ETHEREUM,
        {
            key: ChainEnum.ETHEREUM,
            name: 'Ethereum',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/eth.png',
            explorerUrl: 'https://etherscan.io',
            infoUsedConnectWallet: {
                chainId: '0x1',
                rpcUrls: ['https://cloudflare-eth.com'],
                chainName: 'Ethereum Mainnet',
                blockExplorerUrls: ['https://etherscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.ETHEREUM_SHORT,
        {
            key: ChainEnum.ETHEREUM_SHORT,
            name: 'Ethereum',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/eth.png',
            explorerUrl: 'https://etherscan.io',
            infoUsedConnectWallet: {
                chainId: '0x1',
                rpcUrls: ['https://cloudflare-eth.com'],
                chainName: 'Ethereum Mainnet',

                blockExplorerUrls: ['https://etherscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.ETHEREUM_FULL,
        {
            key: ChainEnum.ETHEREUM_FULL,
            name: 'Ethereum',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/eth.png',
            explorerUrl: 'https://etherscan.io',
            infoUsedConnectWallet: {
                chainId: '0x1',
                rpcUrls: ['https://cloudflare-eth.com'],
                chainName: 'Ethereum Mainnet',

                blockExplorerUrls: ['https://etherscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.POLYGON,
        {
            key: ChainEnum.POLYGON,
            name: 'Polygon',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/polygon.png',
            explorerUrl: 'https://polygonscan.com',
            infoUsedConnectWallet: {
                chainId: '0x89',
                rpcUrls: ['https://polygon-rpc.com'],
                chainName: 'Polygon',

                blockExplorerUrls: ['https://polygonscan.com'],
                nativeCurrency: {
                    symbol: 'MATIC',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.MANTLE,
        {
            key: ChainEnum.MANTLE,
            name: 'Mantle',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/mantle.png',
            explorerUrl: 'https://mantle.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0x1388',
                rpcUrls: ['https://rpc.mantle.xyz'],
                chainName: 'Mantle',

                blockExplorerUrls: ['https://mantle.socialscan.io'],
                nativeCurrency: {
                    symbol: 'MNT',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.POLYGON_ZKEVM,
        {
            key: ChainEnum.POLYGON_ZKEVM,
            name: 'Polygon zkEVM',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/polygon.png',
            explorerUrl: 'https://zkevm.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0x44d',
                rpcUrls: ['https://zkevm-rpc.com'],
                chainName: 'Polygon zkEVM',

                blockExplorerUrls: ['https://zkevm.socialscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.LINEA,
        {
            key: ChainEnum.LINEA,
            name: 'Linea',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/linea.png',
            explorerUrl: 'https://linea.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0xe708',
                rpcUrls: ['https://rpc.linea.build'],
                chainName: 'Linea',

                blockExplorerUrls: ['https://linea.socialscan.io'],
                nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.LINEA_TESTNET,
        {
            key: ChainEnum.LINEA_TESTNET,
            name: 'Linea',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/linea.png',
            explorerUrl: 'https://linea.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0xe704',
                rpcUrls: ['https://rpc.goerli.linea.build'],
                chainName: 'Linea Testnet',

                blockExplorerUrls: ['https://linea.socialscan.io'],
                nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.MANTA_PACIFIC,
        {
            key: ChainEnum.MANTA_PACIFIC,
            name: 'Manta Pacific',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/mantapacific.png',
            explorerUrl: 'https://manta.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0xa9',
                rpcUrls: ['https://pacific-rpc.manta.network/http'],
                chainName: 'Manta Pacific',

                blockExplorerUrls: ['https://manta.socialscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.ARBITRUM,
        {
            key: ChainEnum.ARBITRUM,
            name: 'Arbitrum',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/arbitrum.png',
            explorerUrl: 'https://arbiscan.io',
            infoUsedConnectWallet: {
                chainId: '0xa4b1',
                rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                chainName: 'Arbitrum',

                blockExplorerUrls: ['https://arbiscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.OP,
        {
            key: ChainEnum.OP,
            name: 'Optimism',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/optimism.png',
            explorerUrl: 'https://optimistic.etherscan.io',
            infoUsedConnectWallet: {
                chainId: '0xa',
                rpcUrls: ['https://mainnet.optimism.io'],
                chainName: 'OP Mainnet',

                blockExplorerUrls: ['https://optimistic.etherscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.BASE,
        {
            key: ChainEnum.BASE,
            name: 'Base',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/base.png',
            explorerUrl: 'https://basescan.org',
            infoUsedConnectWallet: {
                chainId: '0x2105',
                rpcUrls: ['https://mainnet.base.org'],
                chainName: 'Base',

                blockExplorerUrls: ['https://basescan.org'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],

    [
        ChainEnum.AVALANCHE_C,
        {
            key: ChainEnum.AVALANCHE_C,
            name: 'Avalanche',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/avax.png',
            explorerUrl: 'https://snowtrace.io',
            infoUsedConnectWallet: {
                chainId: '0xa86a',
                rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                chainName: 'Avalanche C-Chain',

                blockExplorerUrls: ['https://snowtrace.io'],
                nativeCurrency: {
                    symbol: 'AVAX',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.BLAST,
        {
            key: ChainEnum.BLAST,
            name: 'Blast',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/blast.png',
            explorerUrl: 'https://blast.socialscan.io',
            infoUsedConnectWallet: {
                chainId: '0xee',
                rpcUrls: ['https://rpc.blast.io'],
                chainName: 'Blast',

                blockExplorerUrls: ['https://blast.socialscan.io'],
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.BNB_TESTNET,
        {
            key: ChainEnum.BNB_TESTNET,
            name: 'Binance Smart Chain Testnet',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/bnb.png',
            explorerUrl: 'https://testnet.bscscan.com/',
            infoUsedConnectWallet: {
                chainId: '0x61',
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                chainName: 'Binance Smart Chain Testnet',
                blockExplorerUrls: ['https://testnet.bscscan.com/'],
                nativeCurrency: {
                    symbol: 'BNB',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.BSC,
        {
            key: ChainEnum.BSC,
            name: 'Binance Smart Chain',
            wallets: [WalletEnum.WALLET_CONNECT],
            imgUrl: '/assets/imgs/chains/bnb.png',
            explorerUrl: 'https://bscscan.com/',
            infoUsedConnectWallet: {
                chainId: '0x38',
                rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                chainName: 'Binance Smart Chain',
                blockExplorerUrls: ['https://bscscan.com/'],
                nativeCurrency: {
                    symbol: 'BNB',
                    decimals: 18,
                },
            },
        },
    ],
]);

export function getChainInfo(chain: ChainEnum) {
    return ChainInfoMap.get(chain);
}

export function getChainExplorer(chain: ChainEnum) {
    return ChainInfoMap.get(chain)?.explorerUrl;
}

export function getChainName(chain: ChainEnum) {
    return ChainInfoMap.get(chain)?.name;
}

export function getChainImg(chain: ChainEnum) {
    return ChainInfoMap.get(chain)?.imgUrl;
}

export function getChainSymbolIconByChainName(chainName: ChainEnum, blueEth = false) {
    if ([ChainEnum.POLYGON, ChainEnum.MANTLE, ChainEnum.AVALANCHE_C].includes(chainName)) {
        return getChainImg(chainName);
    }

    if (chainName === ChainEnum.ETHEREUM && blueEth) {
        return '/assets/imgs/chains/eth-blue.png';
    }

    return '/assets/imgs/chains/eth.png';
}
