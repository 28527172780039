import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { NotificationService } from '@src/app/services/notification.service';
import { UserService } from '@src/app/services/user.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { SharedModule } from '@src/app/shared/shared.module';
import { LOCAL_STORAGE_KEY_ENUM } from '@src/app/shared/types/storage.type';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ProgressCircleComponent } from '../progress-circle/progress-circle.component';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
    selector: 'app-side-bar',
    standalone: true,
    imports: [CommonModule, SharedModule, NzModalModule, ProgressCircleComponent, NotificationsComponent, NzDropDownModule, SearchResultComponent],
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.less'],
})
export class SideBarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() sideBarCollapsed = false;
    @Input() sideBarTempExpanded = false;
    @Output() toggleSideBar = new EventEmitter<void>();
    @Output() dropdownVisibleChange = new EventEmitter<boolean>();

    steps = [
        'Create an account',
        'Browse dashboard or AI agents for insights',
        'Track your Web3, Crypto, and DeFi portfolio',
        'Join UDF Mining game for rewards',
    ];

    completedSteps: number[] = Array(this.steps.length).fill(null);

    getStartedVisible = false;
    notfiVisible = false;
    searchDropDownVisible = false;

    searchVal = '';

    settingsModalVisible = false;

    updatingAllowFollowers = false;

    destroy$ = new Subject<void>();

    get stepTitle() {
        return `Step ${this.firstUncompletedStep} of ${this.steps.length}`;
    }

    get firstUncompletedStep() {
        return this.completedSteps.findIndex(step => !step) + 1 || this.steps.length;
    }

    get notifications() {
        return this.notificationService.messages;
    }

    get percentage() {
        return (this.completedSteps.filter(Boolean).length / this.steps.length) * 100;
    }

    get hasUnreadNotifications() {
        return this.notifications?.some(n => !n.is_read);
    }

    get isSideBarCollapsedNow() {
        return !this.sideBarTempExpanded && this.sideBarCollapsed;
    }

    constructor(
        public commonService: CommonService,
        public walletStatusService: WalletStatusService,
        public userService: UserService,
        private notificationService: NotificationService,
        private profileService: MyProfileService
    ) {}

    ngOnChanges() {
        this.notfiVisible = false;
    }

    ngOnInit() {
        this.setupStepStatus();

        this.commonService.getStartedStatusChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.setupStepStatus();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    dropdownVisible = false;

    onDropdownVisibleChange(visible: boolean) {
        this.dropdownVisible = visible;
        this.dropdownVisibleChange.emit(visible);
    }

    handleToggleSideBar() {
        this.toggleSideBar.emit();
        this.sideBarTempExpanded = false;
    }

    toggleWalletConnection() {
        if (this.walletStatusService.isWalletConnected) {
            this.walletStatusService.disconnect();
        } else {
            this.commonService.showLogInModal();
        }
    }

    searched() {
        this.searchDropDownVisible = true;
    }

    toggleAllowFollowers(status: boolean) {
        this.updatingAllowFollowers = true;

        this.profileService
            .updateUserProfile({
                allow_follower: status,
            })
            .then(userProfile => this.walletStatusService.userProfile$.next(userProfile))
            .catch(err => {
                this.commonService.errorMessageByResponse(err);
                this.walletStatusService.userProfile.allow_follower = !status;
            })
            .finally(() => (this.updatingAllowFollowers = false));
    }

    setupStepStatus() {
        this.completedSteps[0] = 1;

        if (localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_HOMEPAGE_VIEWED)) {
            this.completedSteps[1] = 1;
        }

        if (localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_MINING_VIEWED)) {
            this.completedSteps[2] = 1;
        }

        if (localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIALSCAN_PROFILE_VIEWED)) {
            this.completedSteps[3] = 1;
        }
    }
}
